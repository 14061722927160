const defaults = {
	setMinHeight: true, additionalHeight:50,
	bannerStageClass:"banner-stage",
	bannerItemClass:"banner",
	navWrapClass:"banner-navigation",
	navItemClass:"banner-nav-item",
	transitionTime: 7000,
	onTransition: (scroller, oldBanner, newBanner) => {
		oldBanner.classList.remove("active");
		newBanner.classList.add("active");
	},
	postTransition: null,
	preTransition: null,
	onSetup:null
};

export class BannerScroll {
	//NB: pass in the outer ".banners" element
	constructor(bannerOuter, config){
		this.config = {...defaults, ...config};
		this.stage = bannerOuter.querySelector(`.${this.config.bannerStageClass}`);
		this.items = Array.from(this.stage.querySelectorAll(`.${this.config.bannerItemClass}`));
		this.nav_wrap = bannerOuter.querySelector(`.${this.config.navWrapClass}`);
		if (this.config.setMinHeight) this.setMinHeight();		
		this.resizeInterval = null;
		this.rotateInterval = null;
		window.addEventListener("resize", this.onResize.bind(this));
		this.resize();
		if(this.items.length > 1)  {
			this.setupNavigation();
			if (this.config.onSetup) this.config.onSetup(this);
			this.start();
		} else {
			this.staticDisplay();
		}
	}

	get currentBanner(){
		return this.items.find(item => item.classList.contains("active"));
	}

	get currentBannerIndex(){
		return this.items.indexOf(this.currentBanner);
	}

	resize() {
		this.setMinHeight();
	}

	onResize() {
		//Debounce the resizing 
		clearTimeout(this.resizeInterval);
		this.resizeInterval = setTimeout(() => {this.resize();}, 100);
	}

	setMinHeight(){
		if (this.items && this.items.length > 0){
			var maxH = 0;
			this.items.forEach(el => {
				var clone = el.cloneNode(true);
				el.after(clone);
				clone.style.position = "relative";
				var height = Math.ceil(clone.getBoundingClientRect().height) + this.config.additionalHeight; //add a lil exta
				clone.remove();
				maxH = (height > maxH)? height: maxH;
			});
			this.stage.style.minHeight = maxH+"px";
		}
	}

	setupNavigation(){				
		this.nav_wrap.style.display = "block";
		this.nav_wrap.style.visibility = "visible";
		var nav_items = this.nav_items = Array.from(this.nav_wrap.querySelectorAll(`.${this.config.navItemClass}`));
		nav_items.forEach((el) => {
			el.addEventListener("click", () => {
				// set nav active
				nav_items.forEach(function(elem){
					elem.classList.remove("active");
				});
				el.classList.add("active");
				var new_index = nav_items.indexOf(el);
				this.moveTo(new_index);
			});
		});
	}

	start() {
		this.rotateInterval = setInterval(() => {this.next();}, this.config.transitionTime);
	}
	pause() {
		clearInterval(this.rotateInterval);
	}

	next(){
		// Moving to the next banner is just a special case of moving between banners.
		//Determine new index		
		var new_index = (this.currentBannerIndex + 1) % this.items.length;
		this.moveTo(new_index);
	}

	moveTo(banner_index){
		this.pause();
		// deactivate navs and active new nav
		this.nav_wrap.querySelector(".active").classList.remove("active");
		this.nav_items[banner_index].classList.add("active");
		// Determine current and new banner
		let currentBanner = this.currentBanner;
		let nextBanner = this.items[banner_index];
		if (currentBanner === nextBanner)
			return;
		this.transition(currentBanner, nextBanner);
		this.start();
	}

	transition(oldBanner, newBanner) {
		if (this.config.preTransation) this.config.preTransition(this, oldBanner, newBanner);
		this.config.onTransition(this, oldBanner, newBanner);
		if (this.config.postTransation) this.config.postTransition(this, oldBanner, newBanner);
	}

	staticDisplay(){
		if (this.config.onStaticDisplay) {
			this.config.onStaticDisplay(this);
		}
	}
}