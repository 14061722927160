import { SVGArcImageMask } from "./SVGArcImageMask.js";

export function arcBannerSetup(scroller) {
	// Add the arc images to each banner. Not sure how to extract images settings yet.
	scroller.items.forEach((banner)=> {
		var arcContainer=document.createElement("div");
		arcContainer.classList.add("arc-container");
		let imageUrl = banner.style.backgroundImage.slice(5, -2);
		banner.style.backgroundImage = "";
		var arc1 = new SVGArcImageMask(
			arcContainer, imageUrl,
			-120, 120, 60, 100
		);
		var arc2 = new SVGArcImageMask(
			arcContainer, imageUrl, //"https://asia.olympus-imaging.com/content/000107506.jpg",
			0, 120, 30, 60, "#6EB5AF"
		);
		var arc3 = new SVGArcImageMask(
			arcContainer, imageUrl, //"https://asia.olympus-imaging.com/content/000107506.jpg",
			120, 120, 30, 60
		);
		banner.arcs = [arc1, arc2, arc3];
		banner.arcContainer = arcContainer; // Store a reference to it.
		banner.appendChild(arcContainer);
		
	});
}
export function arcBannerTransition(scroller, oldBanner, newBanner) {
	console.log("transition");
	
	oldBanner.classList.remove("active");
	newBanner.classList.add("active");

	// DO STUFF WITH ARCS - set up an animation loop
	let start, previousTimeStamp;
	let done = false;
	
	function renderFrame(timestamp){
		if (start === undefined) {
			start = timestamp;
		}
		const elapsed = timestamp - start;
		if (previousTimeStamp !== timestamp) {
			//We want to go through 360 degrees in 1 second, and not over 360
			const angle = Math.min(elapsed * 360 / 1000, 360);
			oldBanner.arcs[0].startAngle = -120 + angle;
			newBanner.arcs[0].startAngle = -120 + angle;
			oldBanner.arcs[1].startAngle = -angle;
			newBanner.arcs[1].startAngle = -angle;
			oldBanner.arcs[2].startAngle = 120 - angle;
			newBanner.arcs[2].startAngle = 120 - angle;
			if (angle === 360) done = true;			
		}
		if (elapsed < 1000) { // Stop the animation after 1 second
			previousTimeStamp = timestamp;
			if (!done) {
				window.requestAnimationFrame(renderFrame);
			}
		}
	}
	window.requestAnimationFrame(renderFrame);
}