const SVG_TEMPLATE = `<svg viewbox="0 0 1000 1000">
<defs>
    <clipPath>
      <path class="arc" fill="none"/>
    </clipPath>
    
  </defs>
  
  <image height="100%" width="100%" />  
  
</svg>`;
const SCALE_FACTOR = 5; // multiply radius percent by 5 to convert to viewbox size. i.e. 100%=500vb radius

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

	return {
		x: centerX + (radius * Math.cos(angleInRadians)),
		y: centerY + (radius * Math.sin(angleInRadians))
	};
}

function describeArc(x, y, innerRadius, outerRadius, startAngle, endAngle) {

	var start = polarToCartesian(x, y, outerRadius, endAngle);
	var startInner = polarToCartesian(x, y, innerRadius, endAngle);
	var end = polarToCartesian(x, y, outerRadius, startAngle);
	var endInner = polarToCartesian(x, y, innerRadius, startAngle);

	var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	var d = [
		"M", start.x, start.y,
		"A", outerRadius, outerRadius, 0, largeArcFlag, 0, end.x, end.y,
		"L", endInner.x, endInner.y,
		"A", innerRadius, innerRadius, 0, largeArcFlag, 1, startInner.x, startInner.y,
		"L", start.x, start.y
	].join(" ");

	return d;
}
var segment_index = 0;

export class SVGArcImageMask {
	constructor(container, image_url, startAngle=0, sweepAngle=90, innerRadius=50, outerRadius=80, overlayColour=null) {
		this.id = 
		this.container = container;
		this.image_url = image_url;
		this._startAngle = startAngle;
		this._sweepAngle = sweepAngle;
		this._innerRadius = innerRadius;
		this._outerRadius = outerRadius;
		this._overlayColour = overlayColour;
		this.setup();
	}
	setup() {
		segment_index++;
		this.segment_index = segment_index;
		this.element = document.createElement("div");
		this.element.classList.add("segment-mask");
		this.element.insertAdjacentHTML("afterbegin", SVG_TEMPLATE);
		this.svg_element = this.element.querySelector("svg");
		this.img_element = this.element.querySelector("image");
		this.img_element.setAttribute("href", this.image_url);
		this.img_element.setAttribute("clip-path",`url(#SVGSegmentMask-clip-${this.segment_index})`);
		this.arc_element = this.element.querySelector(".arc");
		this.clip_element = this.element.querySelector("clipPath");
		this.clip_element.id = "SVGSegmentMask-clip-"+this.segment_index;
		if (this._overlayColour) {
			this.svg_element.insertAdjacentHTML("beforeend",`<rect width="1000", height="1000", style="opacity:0.75; fill:${this._overlayColour};" clip-path="url(#SVGSegmentMask-clip-${this.segment_index})">`);
		}
		this.updateArc();		
		var img = new Image();
		img.onload = () => this.setImageSize(img);
		img.src = this.image_url;
		this.container.appendChild(this.element);
	}
	setImageSize(img){
		let aspect = img.naturalWidth / img.naturalHeight;
		if (aspect > 1) {
			this.img_element.setAttribute("width", (aspect * 100) + "%");
			this.img_element.setAttribute("x", ((100 - (aspect * 100))/2)+"%");
		} else {
			this.img_element.setAttribute("height", ((1/aspect) * 100) + "%");
			this.img_element.setAttribute("y", ((100 - ((1/aspect) * 100))/2)+"%");
		}		
	}
	updateArc(){
		this.arc_element.setAttribute("d", describeArc(500,500, this.innerRadius*SCALE_FACTOR, this.outerRadius*SCALE_FACTOR, this.startAngle, this.finishAngle));
	}

	get startAngle(){
		return this._startAngle;
	}
	set startAngle(angle) {
		this._startAngle = angle;
		this.updateArc();
	}
	
	get sweepAngle(){
		return this._sweepAngle;
	}
	set sweepAngle(angle) {
		this._sweepAngle = angle;
		this.updateArc();
	}
	
	get innerRadius(){
		return this._innerRadius;
	}
	set innerRadius(radius) {
		this._innerRadius = radius;
		this.updateArc();
	}
	
	get outerRadius(){
		return this._outerRadius;
	}
	set outerRadius(radius) {
		this._outerRadius = radius;
		this.updateArc();
	}

	get finishAngle(){
		return this._startAngle+this.sweepAngle;
	}
}