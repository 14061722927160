import "lazysizes";
import {FloatLabels} from "@keodesign/float-labels";
import { arcBannerSetup, arcBannerTransition } from "./banner-animate.js";
import { BannerScroll } from "./banner-scroll.js";
import logoScroller from "@keodesign/logo-scroller";

var html_classes = document.documentElement.classList;

html_classes.remove("no-js");
html_classes.add("js");

const all_banners = document.querySelectorAll(".banners");
const all_scrollers = document.querySelectorAll(".scrollwrap");
const service_scroller = document.querySelector(".scrollwrap.pce_service");

window.onload = function() {
	setupSlideMenu();
	staffClicks();

	setupBanners(true);
	logoScroller(".banners-home_partners .banner-stage", ".banner", "left", 0.1);
	setupScrollers(true);

	// contact page
	new FloatLabels(".js-float-wrap");
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
};

var resizeInt;
window.addEventListener("resize", () =>{
	clearTimeout(resizeInt);
	resizeInt = setTimeout(resetHeights, 100);
	function resetHeights(){
		// important for the testimonial scroller
		setupScrollers();
		// show hide the buttons on services scroller
		if (service_scroller)
			service_show_hide_buttons(service_scroller);
	}
});

export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			document.body.classList.toggle("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
		}
	});
	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});
}

export function staffClicks(){
	const staff_list = document.querySelectorAll(".item.staff");
	const close_modal = (target_bio) => {
		target_bio.classList.remove("active");
		target_bio.parentNode.classList.remove("active");
	};
	staff_list.forEach(el => {
		el.addEventListener("click", () => {
			var target_id = el.getAttribute("data-id");
			var target_bio = document.getElementById(target_id);
			if (target_bio != "undefined"){
				target_bio.classList.add("active");
				target_bio.parentNode.classList.add("active");

				target_bio.parentNode.addEventListener("click", function(e){
					if( e.target !== this) return;
					target_bio.classList.remove("active");
					close_modal(target_bio);
				});
			}
			var closeButton = target_bio.getElementsByTagName("button");

			if (closeButton.length){
				closeButton[0].addEventListener("click", () => {
					target_bio.classList.remove("active");
					close_modal(target_bio);
				});
			}
		
		});
	});
}

/* Banner + scroller functions */
export function setMinHeight(obj_list){
	if (obj_list && obj_list.length > 0){
		var maxH = 0;
		obj_list.forEach(el => {
			var clone = el.cloneNode(true);
			el.after(clone);
			clone.style.position = "relative";
			var height = Math.ceil(clone.getBoundingClientRect().height) + 50; //add a lil extra
			clone.remove();
			maxH = (height > maxH)? height: maxH;
		});
		obj_list[0].parentNode.style.minHeight = maxH+"px";
	}
}

export function setupBanners(){
	if (all_banners.length){
		all_banners.forEach(function(el){
			//NB: that class is the logo-scroller
			if (!el.classList.contains("banners-home_partners")){
				// pass in the outer .banners element to BannerScroller class
				if (el.classList.contains("banners-home_top")){
					new BannerScroll(el, {
						onSetup: arcBannerSetup,
						onTransition: arcBannerTransition
					});
				} else {
					new BannerScroll(el);
				}
			}
		});
	}
}

/** auto fade between testimonials **/
export function scrollTestimonials(items, nav_wrap){
	if(items.length > 1)  {
		nav_wrap.style.display = "block";
		nav_wrap.style.visibility = "visible";

		var nav_items = nav_wrap.querySelectorAll(".scroll-nav-item");
		if (nav_items.length){
			nav_items[0].classList.add("active");
		}
		var rotInt = setInterval(function(){swap(items, nav_items);}, 7000);
		scrollNav(nav_items, items, rotInt, true);
	}
}
export function swap(items, nav_items){
	var scroller = items[0].parentNode;
	var nav_wrap = nav_items[0].parentNode;

	var oldb = scroller.querySelector(".active");
	var newb = scroller.querySelector(".active").nextElementSibling;
	var lastb = items[items.length -1];

	if (!lastb.classList.contains("active")){
		oldb.classList.remove("active");
		newb.classList.add("active");
		var index = [].indexOf.call(scroller.children, newb);

		/* nav */
		nav_wrap.querySelector(".active").classList.remove("active");
		nav_items[index].classList.add("active");
	} else {
		oldb.classList.remove("active");
		items[0].classList.add("active");
		/* banner nav */
		nav_wrap.querySelector(".active").classList.remove("active");
		nav_items[0].classList.add("active");
	}
}
export function scrollNav(nav_items, items, rotInt, next){
	nav_items.forEach(function(el){
		el.addEventListener("click", function(){
			// set nav active
			nav_items.forEach(function(elem){
				elem.classList.remove("active");
			});
			el.classList.add("active");
			var new_index = [].indexOf.call(nav_items, el);
			// set item active
			items.forEach(function(elem, index){
				if (index != new_index){
					elem.classList.remove("active");
				} else {
					elem.classList.add("active");
				}
			});
			// reset rotation
			clearInterval(rotInt);
			rotInt = setInterval(function(){swap(items, nav_items);}, 7000);
		});
	});
	if (next){
		var wrapper = items[0].parentNode.parentNode;
		var next_button = wrapper.querySelector(".next");
		if (next_button){
			next_button.addEventListener("click", function(){
				swap(items, nav_items);
				// reset rotation
				clearInterval(rotInt);
				rotInt = setInterval(function(){swap(items, nav_items);}, 7000);
			});
		}
	}
}


/** Scrollers (Testimonial and Services) **/
export function setupScrollers(first){
	if (all_scrollers.length){
		all_scrollers.forEach(function(el){
			if (el.classList.contains("testimonial")){
				var items = el.querySelectorAll(".testimonial");
				setMinHeight(items);
				if (first){
					var nav_wrap = el.querySelector(".scrollnav");
					scrollTestimonials(items, nav_wrap);
				}
			} else if (first) {
				service_scroll_setup(el);
			}
		});
	}
}

/* beginning of service horizontal scroller  */
export function service_scroll_setup(elem){
	var scroller = elem.querySelector(".scroller");
	var items = scroller.querySelectorAll(".item");
	if (items.length) {
		serviceScroller(elem);
	} else {
		elem.parentNode.style.display = "none";
	}
}

export function service_show_hide_buttons(elem){
	var prev = elem.querySelector(".prev");
	var next = elem.querySelector(".next");
	var scroller = elem.querySelector(".scroller");

	if (scroller.scrollLeft == 0){
		prev.style.display = "none";
	} else {
		prev.style.display = "block";
	}
	if (scroller.scrollLeft + scroller.clientWidth >= scroller.scrollWidth){
		next.style.display = "none";
	} else {
		next.style.display = "block";
	}
}

export function serviceScroller(elem){
	var prev = elem.querySelector(".prev");
	var next = elem.querySelector(".next");
	var scroller = elem.querySelector(".scroller");

	function slideScroller(offset){
		var distance = scroller.clientWidth;
		var new_pos;
		if(scroller.getAttribute("data-animating") != "true"){
			scroller.setAttribute("data-animating", true);
			if (offset < 0){
				//backwards
				if (scroller.scrollLeft > 0){
					new_pos = scroller.scrollLeft - distance;
					move(new_pos);
				} else {
					prev.style.display =  "none";
				}
			} else {
				//forwards
				if (scroller.scrollLeft + scroller.clientWidth < scroller.scrollWidth){
					new_pos = scroller.scrollLeft + distance;
					move(new_pos);
				} else {
					next.style.display = "none";
				}
			}
		}
		// moveit
		function move(pos){
			scroller.scrollLeft = pos;
			// wait a sec
			setTimeout(()=>{
				scroller.setAttribute("data-animating", false);
				service_show_hide_buttons(elem);
			}, 1000);
		}
	}

	// click functions
	prev.addEventListener("click", function(){
		slideScroller(-1);
	});
	next.addEventListener("click", function(){
		slideScroller(1);
	});

	service_show_hide_buttons(elem);
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});